
.subtitle{
    color: #1B1B1B;
    font-size: 28px;
    font-weight: 800;
   
}

  .cards{
    

          box-shadow: none;
    border: 1px solid #C5C5C7 !important;
    
    border-radius: 16px;
  }

  .check{

    display: flex;

  
    align-items: center;
    border: 1px solid #C5C5C7;
    border-radius: 6px;

    min-width: fit-content;
  }

  .activities{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #96969A;
    font-size: 16px;
  }
.states{
  display: inline-flex;

  align-items: center;

  justify-content: center;
  background-color: #000;
  color: #FFF;
  border-radius: 4px;
  padding: 10px;
  height: 30px;
}
.activity_type{
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}