.about_title{
    font-size: 48px;
    font-weight: bold;
    color: #000;
}
.about_sub_title{
    font-size: 28px;
    font-weight: bold;
    color: #000;
}
.about_discription{
    font-size: 16px;
    
}
@media (max-width: 600px) {

    .mobmarginbottom{
        margin-bottom: 60px;
    }

  }