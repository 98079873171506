.search_img {
  background-size: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}

.search_img img {
  transform: scale(1);
  transition: all 0.5s ease;
  display: block;
}

.search_img:hover img {
  transform: scale(1.3);
  transition: all 0.5s ease;
}

.search_img_link:hover {
  color: #f7c32e;
}

.search_img_link a {
  text-decoration: none;
}

.search_img_link a p {
  font-weight: 500;
}

.search_img_link:hover a p {
  text-decoration: underline;
  color: var(--primary);
}

.download_list svg {
  color: var(--primary);
}

.download_img {}

.home_title {
  color: #1b1b1b;
  font-size: 62px;
  font-weight: bold;
}

.titlesection {
  color: #1b1b1b;

  font-weight: bold;
}

.guest_description {
  color: #1b1b1b;
  font-size: 52px;
  font-weight: regular;
}

.custom-swiper {
  transform: translate3d(0, 0, 0);
}

.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
}

.custom-swiper .swiper-button-next::after,
.custom-swiper .swiper-button-prev::after {
  color: #C5C5C7;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #C5C5C7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
}

.star {
  color: #f7c32e;
}

@media (max-width: 900px) {
  .download_img {
    width: 100%;
  }

  .search_img {
    width: 148px;
    margin-bottom: 8px;
  }

  .hm_ftr_payment {
    justify-content: center;
    margin-bottom: 16px;
  }

  .hm_ftr_social_wrapper {
    justify-content: center;
    margin-top: 16px;
  }

  .home_wrapper {
    padding-bottom: 32px;
  }
}


@media (max-width: 600px) {
  .home_title {
    font-size: 30px;
    font-weight: bold;
    color: #1b1b1b;
  }

  .subtitle {
    font-size: 22px;

    color: #1b1b1b;
  }

  .responsive_img {
    max-width: 75%;
  }

  .testimonial_img::after {
    right: 67px;

    top: 34px;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 105px !important;
    left: 201px !important;
  }

  .custom-swiper .swiper-button-next,
  .custom-swiper .swiper-button-prev {
    bottom: 0px;
    max-width: calc(100% - 82px);
    top: unset;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 123px !important;
    right: auto;
  }

  .swiper-button-prev {
    bottom: 0px;
    max-width: calc(100% - 82px);
    top: unset;
  }
}

@media (max-width: 1100px) {
  .scroolm {
    overflow: auto;
  }
}

@media (max-width: 600px) {
  .explore {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .textfieldbottom {
    margin-bottom: 42px;
  }

  .mobmarginbottom {
    margin-bottom: 60px;
  }

  .titlesection {
    font-size: 26px;
  }

  .explore_title {
    margin-bottom: 12px;
  }

  .home_title {
    font-size: 28px;
    font-weight: bold;
    color: #1b1b1b;
    text-align: center;
  }

  .guest_description {
    color: #1b1b1b;
    font-size: 24px;
    text-align: center;
  }

  .subtitle {
    font-size: 22px;

    color: #1b1b1b;
  }

  .scroolm {
    overflow: auto;
  }
}

.fw_home_header_img {
  position: relative;
  right: -30px;
  top: 30px;
}

.searchfont {
  font-size: 12.5px;
  padding: 5px;
}

.featurefont {
  font-size: 16px;
}

@media (min-width: 992px) {

  /* This corresponds to the lg breakpoint */
  .fw_home_img {
    position: relative;
    right: -30px;
    top: 30px;
    max-width: calc(100% + 30px);
  }

  .explore_title {
    margin-bottom: 20px;
  }

  .button_card {
    max-width: 706px;
    padding: 32px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .explore {
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }
}

@-moz-document url-prefix() {
  .swiper-wrapper {
    /* commented below line out,
     it is causing the wrapper 
     to be fixed at 1200px 
     in image preview at package details 
     and property details page
      in header gallary */

    /* width: 1200px; */
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 150px !important;
  }

  .custom-swiper .swiper-button-next,
  .custom-swiper .swiper-button-prev {
    bottom: 0px;
    max-width: calc(100% - 82px);
    top: unset;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 123px !important;
    right: auto;
  }

  .swiper-button-prev {
    bottom: 0px;
    max-width: calc(100% - 82px);
    top: unset;
  }
}

@media only screen and (max-width: 600px) {
  .fw_home_img {
    max-width: 100%;
  }
}


.homePageTabs .MuiTab-iconWrapper {
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
}

.homePageTabs .Mui-selected>.MuiTab-iconWrapper {
  background-color: var(--primary);
}

.homePageTabs .Mui-selected>.MuiTab-iconWrapper img {
  filter: invert(100%) brightness(100%)
}