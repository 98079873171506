.scroll-container {
    max-height: 400px;
    overflow-y: scroll;
  }
  
  /* Firefox */
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Webkit (Chrome, Safari, Opera) */
  .scroll-container {
    scrollbar-width: none;
  }
  