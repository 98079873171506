.safety_title{
    font-size: 28px;
    color: #1B1B1B;
    font-weight: bold;
}
.sub_title{
    font-size: 16px;
    color: #1B1B1B;
    font-weight: bold;
}
.safty_description{
    font-size: 16px;

}
@media (max-width: 600px) {

    .mobmarginbottom{
        margin-bottom: 60px;
    }

  }