body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #747579 !important;
	padding-right: unset !important;
	/* overflow: hidden !important; */
}

:root {
	--primary: #26C77A;
	--black: #121212;
	--grey900: #747579;
	--grey700: #C5C5C7;
	--grey500: #E5E7EB;
	--grey300: #F5F5F6;
	--textGrey: #85878A;
	--warningLight: #fff8e1;
	--warningMain: #ffe57f;
	--warningDark: #ffc107;

	--fwfont: 'Poppins', sans-serif !important;
}


*::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3); */
	background-color: #F5F5F580;
	border-radius: 4px;

}

*::-webkit-scrollbar {
	width: 4px;
	background-color: #F5F5F580;
	height: 3px;
}

*::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background-color: var(--grey900);
	/*background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgba(96, 143, 255, 0.7)), color-stop(0.72, rgba(96, 143, 255, 0.8)), color-stop(0.86, rgba(96, 143, 255, 0.9)));*/
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgba(255, 255, 255, 0.55)), color-stop(0.72, rgba(255, 255, 255, 0.5)), color-stop(0.86, rgba(255, 255, 255, 0.4)));
}


a {
	text-decoration: none;
}

a {
	color: var(--primary);
}

.black {
	color: var(--black)
}

.grey900 {
	color: var(--grey900);
}

.warningLight {
	color: var(--warningLight);
}

.warningMain {
	color: var(--warningMain);
}

.warningDark {
	color: var(--warningDark);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}




.fw_appbar {
	box-shadow: unset !important;
	border-bottom: solid 1px var(--grey500);
}

.fw-drawer-paper {
	border-right: 0 !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
	border-right: 0px !important;
}

.fw_left_nav {
	border-right: solid 1px var(--grey500);
}

.fw_left_nav .Mui-selected {
	color: var(--primary);
}

.fw_left_nav .Mui-selected svg {
	color: var(--primary);
}

.arrow_listing {
	margin: 0;
	padding: 0;
}

.arrow_listing {
	margin: 0;
	padding: 0;
	list-style: none;
}

.pro_heading_details {}

.pro_hd_second_wrapper {
	display: flex;
	align-items: center;
}

.pro_hd_icon_text {
	display: inline-flex;
	margin-right: 30px;
	align-items: center;
}

.pro_heading {
	display: flex;
	justify-content: space-between;
}

.pro_d_slide {
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.pro_d_gallery {
	margin: 20px 0 60px 0;
}

.pro_d_gallery_all_images .pro_d_slide {
	border-radius: 16px;
}

.pro_d_gallery_all_images .pro_d_slide_1 {
	height: 400px;
}

.pro_d_gallery_all_images .pro_d_slide_2 {
	height: 196px;
}

.pro_d_gallery_all_images .pro_d_slide_3 {
	height: 196px;
}

.pro_d_gallery_all_images .pro_d_slide_4 {
	height: 196px;
	position: relative;
}

.pro_d_slide_4 a {
	position: absolute;
	z-index: 2;
	top: calc(50% - 16px);
	width: 100%;
	text-align: center;
	color: #fff;
}

.pro_d_slide_4::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: #00000090;
	border-radius: 16px;
}


.h5_icon_heading {
	display: flex;
}

.listing_data {
	margin-bottom: 30px;
}

.listing_data h5 {
	display: flex;
}

.listing_data h5 .MuiSvgIcon-root {
	margin-right: 8px;
}

.arrow_listing {
	margin: 0;
	padding: 0;
}

.arrow_listing li {
	margin: 0;
	padding: 4px 0;
	display: flex;
	width: 50%;
	float: left;
	padding-right: 20px;
}

.arrow_listing li .MuiSvgIcon-root {
	margin-right: 8px;
}

.clr {
	clear: both;
	height: 0;
}


.shadow_1 {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0, 0.15);
}



.bg_light {
	background-color: #F5F5F6 !important;
}

.MuiCardHeader-title {
	font-weight: 700 !important;
}


.group-button-input .css-hv9xud-MuiInputBase-root-MuiOutlinedInput-root {
	border: solid 0px !important;
}

.upload_image_thumb {
	width: 92px;
	height: 76px;
	position: relative;
	margin: 0 15px 0 10px;
}

.upload_image {
	width: 92px;
	height: 76px;
	border-radius: 8px;
	position: relative;
	background-size: cover;
	background-position: center;
}

.upload_image_del_icon {
	position: absolute !important;
	right: -10px;
	top: -10px;
	z-index: 1;
	background-color: #fff !important;
	padding: 4px !important;
	font-size: 10px !important;
}

.have_q_wrapper {
	background: #fff;
	padding: 10px;
	border-radius: 8px;
}

.have_q_wrapper p {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: initial;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.have_q_wrapper .MuiTypography-caption {
	line-height: 14px;
}

.have_q_thumb {
	min-width: 72px;
	height: 72px;
	background-size: cover;
	background-position: center;
}

.have_q_content {
	margin-left: 10px;
}

.contact_supprt {
	position: sticky;
	top: 208px;
	padding: 30px;
}

.greybox_bg {
	background-color: var(--grey300) !important
}

.grey_bg {
	background-color: var(--grey300) !important
}

.white_bg {
	background-color: #fff !important
}

.icon_counts {
	display: flex;
	align-items: flex-start;
	height: 100%;
}

.icon_counts svg {
	margin-right: 8px;
}


.sticky_title {
	position: sticky;
	top: 60px;
	z-index: 2;
	padding-top: 20px;
}

.bordered_wrapper {
	margin-left: 11px;
}

.linethrough {
	border-top: solid 1px var(--grey500);
	text-align: center;
	margin: 40px 0 15px 0;
}

.linethrough span {
	background-color: #fff;
	font-size: 13px;
	position: relative;
	top: -14px;
	padding: 0 15px;
}

.responsive_img {
	max-width: 100%;
}

.uploaded_img {
	width: 100%;
	height: 280px;
	background-size: cover;
	border-radius: 16px;
	background-position: center;
}

.uploaded_img.selected {
	-webkit-box-shadow: 0 0 0px 3px var(--primary);
	box-shadow: 0 0 0 3px var(--primary);
}

.inline_block_group {
	margin-left: 10px;
}

/* homepage css */
.home_wrapper {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100%;
	background: #F5F5F6;
	padding-bottom: 96px;
}
.our_story_page_wrapper {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100%;
	/* background: #F5F5F6; */
	padding-bottom: 48px;
	padding-top: 168px;
}

.hm_hdr_title {
	font-size: 62px;
	line-height: 72px;
	color: var(--black);
	font-weight: bold;
	margin-top: 100px;
}

.hm_hdr_c2a {
	margin: 30px 0;
}

.fw_home_header_img {
	position: relative;
	right: -30px;
	top: 30px
}

.activity_suggestion {
	margin: 30px 0 0 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

.activity_suggestion li {
	margin: 0 8px 16px 0;
	padding: 0 10px;
	list-style: none;
	font-size: 10px;
	font-weight: bold;
}

.activity_suggestion li img {
	display: block;
	margin: auto;
}

.activity_icon {
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.fea_prop_img {
	width: 100%;
	min-height: 196px;
	background-size: cover;
	border-radius: 16px;
	position: relative;
}

.fea_prop {}

.fea_prop_location {
	position: absolute;
	background: #00000098;
	color: #fff;
	padding: 3px 8px;
	display: flex;
	align-items: center;
	border-radius: 30px;
	left: 10px;
	bottom: 10px;
	font-size: 16px;
}

.fea_prop h4 {
	font-size: 20px;
	color: var(--black);
	margin: 15px 0 8px 0;
}

.fea_prop_pricing {
	color: var(--primary);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.fea_prop_rating {
	display: flex;
}

.fea_prop_rating svg {
	color: var(--warningDark);
}

.title_l {
	font-size: 48px;
	line-height: 58px;
	color: var(--black);
}

.title_xl {
	font-size: 62px;
	line-height: 72px;
}

.text-center {
	text-align: center;
}

.responsive_img {
	max-width: 100%;
}

.hm_circle_icon {
	background: #FDF2D2;
	width: 58px;
	height: 58px;
	border-radius: 58px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
	color: var(--black);
}

.testimonial_img {
	position: relative;
	margin: 30px 0 0 30px;
}

.testimonial_img img {
	position: relative;
	z-index: 1;
}

.testimonial_img::after {
	content: url("../public/assets/images/homepage/dots.svg");
	position: absolute;
	width: 30px;
	height: 290px;
	right: 5px;
	z-index: 0;
	top: 90px;
}



.help_box {
	display: flex;
	flex-direction: row;
}

.sub_footer {
	background: #fff;
	padding: 20px;
	border-radius: 16px;
}

.sub_footer_icon {
	margin-right: 15px;
}

.marketplace_icon {
	display: flex;
}

.marketplace_icon img {
	margin-right: 12px;
	max-width: 100%;
}

.marketplace_heading h5 {
	font-size: 28px;
	margin-bottom: 15px;
}

.hm_footer {
	color: #fff;
}

.hm_footer h4 {
	font-size: 20px;
	font-weight: bold;
	color: #fff;
}

.hm_footer hr {}

.hm_ftr_list {
	margin: 0 0 60px 0;
	padding: 0;
}

.hm_ftr_list li {
	margin: 0;
	padding: 10px 0;
	list-style: none;
	color: #fff;
	font-size: 14px;
}

.fw_home_feature_image {
	position: relative;
}

.hm_ftr_list_inline {
	margin: 0;
	padding: 0;
}

.hm_ftr_list_inline li {
	margin: 0 24px 0 0;
	padding: 4px 0;
	display: inline-block;
	list-style: none;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}

.hm_ftr_list_inline li a:hover {
	color: #26C77A !important;
}

.hm_ftr_social {
	margin: 0;
	padding: 0;
	text-align: right;
}

.hm_ftr_social li {
	margin: 0 0 0 16px;
	padding: 0;
	list-style: none;
	display: inline-block;
}

.hm_ftr_social li img {
	width: 42px;
}

.hm_ftr_payment {
	display: flex;
}

.hm_ftr_social_wrapper {
	display: flex;
	text-align: right;
	align-items: center;
}

.hm_ftr_topsearch {
	margin: 0px 0 0 0;
}

.hm_ftr_logo {
	color: #fff;
	padding-right: 30px;
	margin-bottom: 60px;
}

.hm_sub_ftr {}

.hm_search_wrapper {
	width: calc(100% + 200px);
	z-index: 10;
	position: relative;
}

.hm_search {
	background: #fff;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	position: relative;
	z-index: 1;
	padding: 30px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hm_search_location,
.hm_search_date {
	display: flex;
	align-items: center;
}

.hm_search_location svg,
.hm_search_date svg {
	margin-right: 0px;
}

.searchDates:hover {
	border-color: var(--black) !important;
}

.hm_seach_btn {
	height: 54px;
	width: 54px;
	min-width: 54px;
	border-radius: 54px;
	background: var(--primary);
	color: #fff;
	margin-top: 0px;
}

.hm_search_location_text,
.hm_search_date_text {
	margin-bottom: 0;
}

.hm_search_title {
	font-weight: 600;
	color: var(--black);
	margin-bottom: 10px;
}

.hm_search_location_icon,
.hm_search_date_icon {
	display: flex;
	align-items: center;
}


/* filemanager */
.folder_icon {
	width: 60px;
	height: 60px;
	background: var(--grey500);
	border-radius: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.folder_name span {
	color: var(--grey900);
}

.card_folder {
	display: flex;
	align-items: center;
	position: relative;
}

.fm_more {
	position: absolute;
	right: 10px;
	top: 10px;
	display: none;
}

.card_folder:hover {
	border: solid 1px var(--black);
}

.card_folder .select_checkbox {
	display: none;
}

.card_folder:hover .select_checkbox {
	display: block;
}

.card_folder:hover .fm_more {
	display: block;
}

.card_folder:hover .folder_icon_size {
	display: none;
}

.card_folder.selected {
	border-color: var(--primary);
}

.card_folder.selected .select_checkbox {
	display: block;
}

.card_folder.selected .folder_icon_size {
	display: none;
}

.fm_file {
	height: 160px;
	width: 100%;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center;
}

.card_file {
	position: relative;
}

.card_file .fm_more {
	background: var(--grey300);
	border-radius: 60px;
}

.card_file .fm_more {
	background: var(--grey300);
	border-radius: 60px;
}

.card_file:hover .fm_more {
	display: block;
}

.card_file .select_checkbox {
	position: absolute;
	top: 10px;
	left: 10px;
	display: none;
}

.card_file:hover .select_checkbox {
	display: block;
}

.card_file.selected {
	border-color: var(--primary);
}

.card_file.selected .select_checkbox {
	display: block;
}

.fm_file_icon {
	max-width: 90px;
	max-height: 90px;
}

.file_uploader {
	border: dashed 1px var(--grey700);
	padding: 40px;
	background: var(--grey300);
}

.uploaded_file {
	width: 36px;
	height: 36px;
	background-size: cover;
	margin-right: 12px;
}

.uploaded_file_action {
	margin-left: auto;
	align-items: center;
	display: flex;
}

.uploaded_file_name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 65%;
}

.fm_header {
	position: relative;
}

.fm_action {
	background: var(--grey300);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	/* height: 100%; */
	z-index: 9;
}

.fm_detail_img {
	width: 100%;
	height: 268px;
	background-size: cover;
	background-position: center;
}

.filedetails_drawer {
	z-index: 99999 !important;
}

.fm_file .Mui-checked {
	background: #00000069;
}

.fm_file:hover .MuiCheckbox-root {
	background: #00000069;
	color: var(--primary)
}


/* search page */
.exp_listing_left {}

.top_hdr_activity_input .MuiInputBase-root {
	padding-right: 5px;
}

.top_hdr_activity_input fieldset {
	border: 0px;
}

.top_hdr_date_input fieldset {
	border-radius: 0;
}

.top_hdr_search_input .MuiInputBase-root {
	border: 0px;
}

/* host property page  */
.arrow_listing_full {
	margin: 0;
	padding: 0;
}

.arrow_listing_full li {
	margin: 0;
	padding: 4px 0;
	display: flex;
	width: 100%;
	float: left;
	padding-right: 20px;
}

.arrow_listing_full li .MuiSvgIcon-root {
	margin-right: 8px;
}

.map-container {
	height: calc(100vh - 72px);
}

/* package details page swiper bullet color */
.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
	transition: 200ms transform, 200ms left;
	background-color: white;
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	z-index: 1;
}


.swiper-button-next {
	color: rgba(0, 0, 0, 0.54) !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #ddd;
	/* z-index: 9999; */
}

.swiper {
	z-index: unset !important;
}

.customSwiper:hover .swiper-button-next,
.customSwiper:hover .swiper-button-next:after,
.customSwiper:hover .swiper-button-prev,
.customSwiper:hover .swiper-button-prev:after {
	display: flex;
}

.customSwiper .swiper-button-next {
	color: #000000 !important;
	height: 24px !important;
	width: 24px !important;
	border-radius: 50%;
	background-color: #FFFFFF;
	/* z-index: 9999; */
	top: var(--swiper-navigation-top-offset, 56%);
	display: none;
}

.customSwiper .swiper-button-prev {
	color: #000000 !important;
	height: 24px !important;
	width: 24px !important;
	border-radius: 50%;
	background-color: #FFFFFF;
	/* z-index: 9999; */
	top: var(--swiper-navigation-top-offset, 56%);
	display: none;
}

@media screen and (max-width: 600px) {

	.customSwiper .swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 10px !important;
		left: unset !important;
		/* z-index: 9999; */
	}

	.customSwiper .swiper-button-prev,
	.swiper-container-rtl .swiper-button-prev {
		left: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper .swiper-button-next,
	.customSwiper .swiper-button-next:after,
	.customSwiper .swiper-button-prev,
	.customSwiper .swiper-button-prev:after {
		display: flex;
	}
}

@media screen and (max-width: 600px) {

	.customSwiper2 .swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 10px !important;
		left: unset !important;
		/* z-index: 9999; */
	}

	.customSwiper2 .swiper-button-prev,
	.swiper-container-rtl .swiper-button-prev {
		left: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper2 .swiper-button-next,
	.customSwiper2 .swiper-button-next:after,
	.customSwiper2 .swiper-button-prev,
	.customSwiper2 .swiper-button-prev:after {
		display: flex;
	}
}

@media screen and (min-width: 600px) and (max-width: 900px) {

	.customSwiper .swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper .swiper-button-prev,
	.swiper-container-rtl .swiper-button-prev {
		left: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper .swiper-button-next,
	.customSwiper .swiper-button-next:after,
	.customSwiper .swiper-button-prev,
	.customSwiper .swiper-button-prev:after {
		display: flex;
	}
}

@media screen and (min-width: 600px) and (max-width: 899px) {

	.customSwiper2 .swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 10px !important;
		/* z-index: 29999; */
	}

	.customSwiper2 .swiper-button-prev,
	.swiper-container-rtl .swiper-button-prev {
		left: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper2 .swiper-button-next,
	.customSwiper2 .swiper-button-next:after,
	.customSwiper2 .swiper-button-prev,
	.customSwiper2 .swiper-button-prev:after {
		display: flex;
	}
}


.swiper-button-prev {
	color: rgba(0, 0, 0, 0.54) !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 50%;
	background-color: #ddd;
}



.swiper-button-next:after {
	font-family: swiper-icons;
	font-size: 24px !important;
	text-transform: none !important;
	letter-spacing: 0;
	font-variant: initial;
	line-height: 1;
	/* z-index: 9999; */

}

.customSwiper .swiper-button-next:after {
	font-family: swiper-icons;
	font-size: 12px !important;
	text-transform: none !important;
	letter-spacing: 0;
	font-variant: initial;
	line-height: 1;
	/* z-index: 9999; */
}

.swiper-button-prev:after {
	font-family: swiper-icons;
	font-size: 24px !important;
	text-transform: none !important;
	letter-spacing: 0;
	font-variant: initial;
	line-height: 1;
}

.customSwiper .swiper-button-prev:after {
	font-family: swiper-icons;
	font-size: 12px !important;
	text-transform: none !important;
	letter-spacing: 0;
	font-variant: initial;
	line-height: 1;
	/* z-index: 9999; */
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	opacity: 0 !important;
	cursor: auto;
	pointer-events: none;
}

.customSwiper .swiper-pagination-bullet {
	opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
	/* z-index: 9999; */
}

/* contact us send us messages */
.contactUs {
	margin-right: 16px;
	margin-left: 8px;
}

@media (max-width: 768px) {
	.fw_logo {
		display: none;
	}

	.fw_hdr_toggle_btn {
		margin: 0 10px 0 -10px !important;
		color: var(--black);
	}

	.fw_hdr_avtar {
		margin-right: 15px;
	}

	.fw_left_nav {
		position: absolute;
		box-shadow: 10px 0px 30px #00000042;
		z-index: 9999;
		height: 100svh;  /*Changed vh to svh to check chat scroll UI issue*/
		overflow: auto;
	}

	.fw_hdr_leftnav_logo {
		display: flex;
		width: 100%;
		text-align: left;
		margin-left: 12px;
	}

	.fw_hdr_leftnav_logo img {
		width: 120px;
	}

	.uploaded_img {
		height: 160px;
	}

	/* homepage */
	.fw_home_header_img {
		right: 0;
	}

	.home_wrapper {
		background-size: 280%;
		background-repeat: no-repeat;
	}	
	.our_story_page_wrapper {
		background-size: 280%;
		background-repeat: no-repeat;
		padding-top: 80px;
	}

	.hm_hdr_title {
		font-size: 36px;
		line-height: 46px;
		font-weight: bold;
		margin-top: 30px;
	}

	.hm_ftr_social_wrapper {
		text-align: center;
	}

	.hm_ftr_social {
		text-align: center;
	}

	.hm_ftr_social li:first-child {
		margin-left: 0;
	}

	.hm_ftr_list {
		margin-bottom: 30px;
	}

	.hm_ftr_list li {
		padding: 6px 0;
	}

	.marketplace_heading {
		margin-top: 30px;
	}

	.sub_footer {
		margin-bottom: 15px;
	}

	.fea_prop_img {
		min-height: 150px;
	}

	.fea_prop_pricing span {
		font-size: 9px;
	}

	.fea_prop_location {
		/* font-weight: bold; */
		font-size: 14px;
	}

	.title_l {
		font-size: 32px;
	}

	.hm_search {
		padding: 16px;
	}

	.fw_home_feature_image {
		position: relative;
		left: 0;
	}

	/* .hm_search_location_icon{display: none;}
  .hm_search_date_icon{display: none;} */
	.hm_search_location_text,
	.hm_search_date_text {
		margin-bottom: 15px;
	}

	.hm_seach_btn {
		margin-top: 0px;
	}

	.hm_search_location,
	.hm_search_date {}

	.hm_search {
		display: block;
		text-align: center;
	}

	.hm_search_wrapper {
		width: 100% !important;
	}

	.hm_search_date .MuiTextField-root {
		min-width: unset !important;
	}

	/* search page
	.exp_listing_left {}

	.search_filter {
		border-radius: 6px;
	}

	.search_filter.active {
		background: #fff;
		filter: invert(100%);
	}

	.search_filter_wrapper {
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
	}

	.top_hdr_search_input .MuiInputBase-root {
		-webkit-border-radius: 8px;
		-webkit-border-top-right-radius: 8px;
		-webkit-border-bottom-right-radius: 8px;
		-moz-border-radius: 8px;
		-moz-border-radius-topright: 8px;
		-moz-border-radius-bottomright: 8px;
		border-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-left: 0;
		padding: 2px 3px 2px 2px;

	}


	.map-container {
		height: calc(100vh - 139px) !important;
	} */

	/* property/ package page  */
	.pro_hd_second_wrapper {
		display: block;
	}

	.pro_heading_details h3 {
		font-size: 24px;
	}

	.pro_hd_icon_text {
		width: 100%;
	}

	.pro_d_gallery_all_images .pro_d_slide_1 {
		height: 200px;
	}

	.title_page_section {
		font-size: 24px;
	}


}

/* Added this for hamburger menu show hide conditions */

.inside_humburger{display: none;} 
@media (max-width: 900px) {
.outside_humburger{
display: none;
}
.inside_humburger{display: block;}

}
/* Added above for hamburger menu show hide conditions */

.sidebar {
	background-color: rgba(35, 55, 75, 0.9);
	color: #fff;
	padding: 6px 12px;
	font-family: monospace;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	margin: 12px;
	border-radius: 4px;
}



.viewMorePopupover .MuiListItemButton-root:hover {
	background-color: #E0F7EC;
	color: var(--primary) !important;

}

/* .viewMorePopupover .MuiListItemButton-root:hover .MuiListItemIcon-root {
	color: var(--primary) !important;
} */

.viewMorePopupover .MuiListItemButton-root {

	padding-top: 3.5px;
	padding-bottom: 3.5px;
	margin-bottom: 2px;
}

.viewMorePopupover .MuiTypography-root {
	font-size: 0.875rem;
}

.viewMorePopupover .MuiPaper-rounded {
	border-radius: 8px;
	padding: 0.5rem;
}

.mapboxgl-popup-content {
	border-radius: 16px !important;
	/* top: -50px; */
	font-size: 16px;
	padding: 0px !important;
	font-family: 'Poppins';
	color: #747579;
	cursor: pointer;
	max-width: 320px;
}

.mapboxgl-popup-tip {
	display: none;
}

.mapboxgl-popup {
	max-width: unset !important;
	z-index: 11; /*Added this for map pin coming on card issue, it was not here before*/
}

.mapboxgl-popup-content img {
	margin: 0 0 10px 0px;
	border-radius: 8px;
	width: 100%;
	height: 176px;
}

.mb_property_image {
	width: 100%;
	height: 175px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top-right-radius: 16px;	
	border-top-left-radius: 16px;	
	/* margin-bottom: 22px; */
	position: relative;
	/*Added for favourite icon on map lsting*/
}



.mapboxgl-popup-content .mb_property_title {
	font-weight: 700;
	color: #1B1B1B;
	margin: 0 0 10px 0px;
	max-width: 320px;
	font-size: 20px;
}

.mapboxgl-popup-content .mb_property_address {
	margin: 0 0 10px 0px;
	max-width: 320px;
	font-size: 16px;
}

.mapboxgl-popup-content .mb_property_review_card {
	display: flex;
	margin: 0 0 10px 0px;
	color: #1B1B1B;
}

.mapboxgl-popup-content .mb_property_review_card .mb_rating {
	/* font-size: 16px; */
	font-weight: bold;
	margin-right: 5px;
}

.mapboxgl-popup-content .mb_property_review_card .mb_rating {
	color: #1B1B1B;
	/* font-size: 16px; */
	font-weight: bold;
	margin-right: 5px;
}

.mapboxgl-popup-content .mb_property_review_card .mb_rating {
	color: #1B1B1B;
	/* font-size: 16px; */
	font-weight: bold;
	margin-right: 5px;
}

.mapboxgl-popup-content .mb_chip_container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 8px;
}

.mapboxgl-popup-content .mb_chip {
  background-color: #F5F5F6;
  border-radius: 24px;
  text-wrap: nowrap;
  padding-block: 4px;
  padding-inline: 10px;
  display: flex;
  align-items: center;
}
.mapboxgl-popup-content .mb_activity_type {
 font-size: 14px;
}

.mapboxgl-popup-content .data-wrapper {
	padding: 24px !important;
 }
 
 .mapboxgl-popup-content .pop-up-content {
	width: 274px !important;
 }

.Toastify__toast-container {
	z-index: 999999 !important;
}

#launcher {
	z-index: 999997 !important;
}



@media (max-width: 900px) {

	/* search page */
	.exp_listing_left {}

	.search_filter {
		border-radius: 6px;
	}

	.search_filter.active {
		background: #1b1b1b;
		filter: invert(100%);
	}

	.search_filter_wrapper {
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
	}

	.top_hdr_search_input .MuiInputBase-root {
		-webkit-border-radius: 8px;
		-webkit-border-top-right-radius: 8px;
		-webkit-border-bottom-right-radius: 8px;
		-moz-border-radius: 8px;
		-moz-border-radius-topright: 8px;
		-moz-border-radius-bottomright: 8px;
		border-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-left: 0;
		padding: 2px 3px 2px 2px;

	}


	.map-container {
		height: calc(100vh - 139px) !important;
	}


	.mapboxgl-popup-content {
		border-radius: 8px !important;
		font-size: 16px;
		padding: 0.5rem !important;
		font-family: 'Poppins';
		color: #747579;
		cursor: pointer;
		max-width: 176px;
	}

	.mapboxgl-popup-tip {
		display: none;
	}

	.mapboxgl-popup {
		max-width: unset !important;
	}

	.mapboxgl-popup-content img {
		margin: 0 0 10px 0px;
		border-radius: 8px;
		width: 100%;
		height: 176px;
	}

	.mapboxgl-popup-content .mb_activity_type {
		font-size: 12px;
	   }

	.mb_property_image {
		width: 160px;
		height: 100px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border-top-right-radius: 16px;	
	    border-top-left-radius: 16px;
		margin-bottom: 8px;
	}


	/*Added for favourite icon on map lsting*/
	.mb_property_image #mb_fav_icon {
		height: 16px !important;
		width: 16px !important;
		z-index: 1000 !important;
		position: absolute;
		/* background-image: url(/assets/images/icon_images/star-solid.svg); */
		top: 29px;
		right: 34px;
		background-repeat: no-repeat;
	}


	.mapboxgl-popup-content .mb_property_title {
		font-weight: 700;
		color: #1B1B1B;
		margin: 0 0 8px 0px;
		max-width: 160px;
		font-size: 16px;
		word-break: break-all;
	}

	.mapboxgl-popup-content .mb_property_address {
		margin: 0 0 8px 0px;
		max-width: 160px;
		font-size: 14px;
	}

	.mapboxgl-popup-content .mb_property_review_card {
		display: flex;
		margin: 0 0 8px 0px;
		color: #1B1B1B;
	}

	.mapboxgl-popup-content .mb_property_review_card .mb_rating {
		/* font-size: 16px; */
		font-weight: bold;
		margin-right: 5px;
	}

	.mapboxgl-popup-content .mb_property_review_card .mb_rating {
		color: #1B1B1B;
		/* font-size: 16px; */
		font-weight: bold;
		margin-right: 5px;
		font-size: 12px;
	}

	.mapboxgl-popup-content .mb_property_review_card .mb_rating {
		color: #1B1B1B;
		/* font-size: 16px; */
		font-weight: bold;
		margin-right: 5px;
	}

	.mb_property_review_card {
		font-size: 12px;
		margin-bottom: 8px;
	}

}

@media (max-width: 1280px) {

	/* search page */
	.exp_listing_left {}

	.search_filter {
		border-radius: 6px;
	}

	.search_filter.active {
		background: #1b1b1b;
		filter: invert(100%);
	}

	.search_filter_wrapper {
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
	}

	.top_hdr_search_input .MuiInputBase-root {
		-webkit-border-radius: 8px;
		-webkit-border-top-right-radius: 8px;
		-webkit-border-bottom-right-radius: 8px;
		-moz-border-radius: 8px;
		-moz-border-radius-topright: 8px;
		-moz-border-radius-bottomright: 8px;
		border-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-left: 0;
		padding: 2px 3px 2px 2px;

	}


	.map-container {
		/* height: 100vh !important; */
	}


	.mapboxgl-popup-content {
		border-radius: 16px !important;
		font-size: 16px;
		padding: 0px !important;
		font-family: 'Poppins';
		color: #747579;
		cursor: pointer;
		max-width: 176px;
	}

	.mapboxgl-popup-tip {
		display: none;
	}

	.mapboxgl-popup {
		max-width: unset !important;
	}

	.mapboxgl-popup-content img {
		margin: 0 0 10px 0px;
		border-radius: 8px;
		width: 100%;
		height: 176px;
	}
	.mapboxgl-popup-content .mb_activity_type {
		font-size: 12px;
	   }

	.mb_property_image {
		width: 100%;
		height: 100px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border-top-right-radius: 16px;	
	    border-top-left-radius: 16px;
		margin-bottom: 8px;
	}

	.mapboxgl-popup-content .pop-up-content {
		width: 100% !important;
	 }


	/*Added for favourite icon on map lsting*/
	.mb_property_image #mb_fav_icon {
		height: 16px !important;
		width: 16px !important;
		z-index: 1000 !important;
		position: absolute;
		/* background-image: url(/assets/images/icon_images/star-solid.svg); */
		top: 29px;
		right: 34px;
		background-repeat: no-repeat;
	}


	.mapboxgl-popup-content .mb_property_title {
		font-weight: 700;
		color: #1B1B1B;
		margin: 0 0 8px 0px;
		max-width: 160px;
		font-size: 16px;
		word-break: break-word;
	}

	.mapboxgl-popup-content .data-wrapper {
       padding: 16px !important;
	}

	.mapboxgl-popup-content .mb_property_address {
		margin: 0 0 8px 0px;
		max-width: 160px;
		font-size: 14px;
	}

	.mapboxgl-popup-content .mb_property_review_card {
		display: flex;
		margin: 0 0 8px 0px;
		color: #1B1B1B;
	}

	.mapboxgl-popup-content .mb_property_review_card .mb_rating {
		/* font-size: 16px; */
		font-weight: bold;
		margin-right: 5px;
	}

	.mapboxgl-popup-content .mb_property_review_card .mb_rating {
		color: #1B1B1B;
		/* font-size: 16px; */
		font-weight: bold;
		margin-right: 5px;
		font-size: 12px;
	}

	.mapboxgl-popup-content .mb_property_review_card .mb_rating {
		color: #1B1B1B;
		/* font-size: 16px; */
		font-weight: bold;
		margin-right: 5px;
	}

	.mb_property_review_card {
		font-size: 12px;
		margin-bottom: 8px;
	}

}

.swiper-slide {
	width: 100vw;
}

/* 
.MuiDialog-root {
	z-index: 999999;
} */


@media screen and (max-width: 600px) {

	.customSwiper2 .swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 10px !important;
		left: unset !important;
		/* z-index: 9999; */
	}

	.customSwiper2 .swiper-button-prev,
	.swiper-container-rtl .swiper-button-prev {
		left: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper2 .swiper-button-next,
	.customSwiper2 .swiper-button-next:after,
	.customSwiper2 .swiper-button-prev,
	.customSwiper2 .swiper-button-prev:after {
		display: flex;
	}
}

@media screen and (min-width: 600px) and (max-width: 899px) {

	.customSwiper2 .swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: 10px !important;
		/* z-index: 29999; */
	}

	.customSwiper2 .swiper-button-prev,
	.swiper-container-rtl .swiper-button-prev {
		left: 10px !important;
		/* z-index: 9999; */
	}

	.customSwiper2 .swiper-button-next,
	.customSwiper2 .swiper-button-next:after,
	.customSwiper2 .swiper-button-prev,
	.customSwiper2 .swiper-button-prev:after {
		display: flex;
	}
}

/* removing it will cause,
 if one video or photo will be there in 
 package details or property details it
  will be shown in small view */

.SwiperActiveSlide {
	/* width: 100vw !important; */
	height: 100vh !important;
	display: flex !important;
	align-items: center;
}

.authPopUpP {
	z-index: 999999;
}


.Toastify {
	position: absolute;
	z-index: 9999999 !important;
}

.MuiToolbar-root {
	height: 72px;
}

.mapboxgl-ctrl-group {
	margin-top: 24px !important;
	margin-left: 16px !important;
}

/* .mapboxgl-ctrl-bottom-left{
	display: none;
} */


@media screen and (max-width:1024px) {
	.fm_action {
	  background: var(--grey300);
	  position: fixed; /*Added this for mobile and desktop position fixed*/
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  /* height: 100%; */
	  z-index: 9;
	}
  }

  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  
  }

  @media screen and (min-width: 900px) {
	.twoLinerEllipsis {
	  white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
  
  
	  @supports (-webkit-line-clamp: 2) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	  }
	}
  }

  .iconColor {
	color: #1B1B1B !important;
  }

  .applyStyles p {
	margin: 0 !important;
  }
  
  .quill.ql-error {
	.ql-toolbar {
	  border-bottom: 1px solid red;
	}
	.ql-container {
	  border-left: 1px solid red;
	  border-right: 1px solid red;
	  border-bottom: 1px solid red;
	}
  }